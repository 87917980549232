.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adId {
  font-size: 16px;
}

.relatedResources {
  margin-left: 24px;
}