.root {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.root:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 46px;
  padding: 14px 24px;
  border: 1px solid #3e7eff;
  border-radius: 24px;
  background-color: #3e7eff;
  color: #fff;
  line-height: 18px;
  transition: opacity 0.2s ease-in-out, border 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.root:hover {
  background-color: #0054fd;
  border: 1px solid #0054fd;
}
.root:active {
  background-color: #3e7eff;
  border: 1px solid #3e7eff;
}
.root_disabled {
  pointer-events: none;
  opacity: 0.6;
}
.root_withLargeSideInnerGaps {
  padding-left: 40px;
  padding-right: 40px;
}
.root_expanded {
  width: 100%;
}
.root_small {
  height: 38px;
  padding: 10px 16px;
}

.root_variant--secondary {
  background-color: transparent;
  border: 1px solid #3e7eff;
  color: #3e7eff;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.root_variant--secondary:hover {
  border: 1px solid #3e7eff;
  background-color: #3e7eff;
  color: #fff;
}
.root_variant--secondary:active {
  background-color: transparent;
  color: #3e7eff;
}

.root_variant--flat {
  background-color: transparent;
  border: 1px solid transparent;
  color: #3e7eff;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}
.root_variant--flat:hover {
  border: 1px solid #3e7eff;
  background-color: transparent;
}
.root_variant--flat:active {
  border: 1px solid #0054fd;
  background-color: #0054fd;
  color: #fff;
}

.title {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prependedIcon {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 8px;
}

.appendedIcon {
  flex: 0 0 auto;
  display: inline-block;
  margin-left: 8px;
}