.root {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: #afb5c4;
  line-height: 16px;
  font-size: 12px;
  color: #222;
  text-align: center;
  text-transform: lowercase;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 6px;
  line-height: 1;
}