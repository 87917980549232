.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #575757;
}

.label {
  margin-right: 12px;
}

.select {
  flex: 1 1 auto;
}