.root {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.root:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  width: 100%;
  height: 46px;
  padding: 14px 36px;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #fff;
  line-height: 18px;
  font-size: 16px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}
.root:hover, .root:active {
  background-color: #f5f6f9;
}

.icon {
  display: inline-block;
  height: 18px;
  margin-right: 8px;
}