.content {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 18px;
}
.root:last-child {
  margin-right: 0;
}

.content {
  display: inline-flex;
  align-items: center;
  padding: 4px 0;
  transition: opacity 0.2s ease-in-out;
}
.content:hover {
  opacity: 0.7;
}
.content:active {
  opacity: 1;
}
.content_interactive {
  pointer-events: none;
}

.title {
  font-weight: 700;
}

.icon {
  margin-left: 8px;
  line-height: 1;
}

.actionContainer {
  display: flex;
  align-items: center;
  height: 38px;
  margin-top: 4px;
}

.action {
  flex: 1 1 auto;
  max-width: 100%;
}