.root {
  padding: 24px 32px;
  border-radius: 8px;
  background-color: #f5f6f9;
}

.title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legend {
  width: 240px;
  margin-right: 24px;
}

.legendItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.legendItem:last-child {
  margin-bottom: 0;
}

.legendItemKey {
  display: inline-flex;
  align-items: center;
}

.legendItemMarker {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 16px;
}