.root {
  display: flex;
  overflow: hidden;
}

.userList {
  flex: 0 0 64px;
  overflow: hidden;
}

.userItem {
  margin-bottom: 12px;
  text-align: center;
}
.userItem:last-child {
  margin-bottom: 0;
}

.userMedia {
  display: inline-block;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2px;
}

.userName {
  line-height: 1.2;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.axisBottomTrick {
  font-size: 10px;
}

.timeline {
  flex: 1 1 auto;
  overflow: auto;
}

.barRounded {
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
.barRounded:hover {
  opacity: 0.8;
}
.barRounded:active {
  opacity: 1;
}