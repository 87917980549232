.status {
  color: #afb5c4;
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  margin-top: 16px;
}

.gridItem:not(:last-child) {
  margin-bottom: 8px;
}