.root {
  padding: 24px 32px;
  border-radius: 24px;
}

.header {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.headerIcon {
  margin-right: 8px;
}

.reprocessingLabel {
  margin-left: 24px;
}

.content {
  max-width: 50%;
  margin-top: 12px;
}