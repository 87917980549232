.root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  font-weight: 700;
  color: #222;
  text-align: center;
}

.title {
  margin-bottom: 24px;
  font-size: 26px;
}

.subtitle {
  margin-bottom: 40px;
  font-size: 18px;
}

.image {
  margin-bottom: 32px;
  margin-left: -24px;
}