@import '../../../../styles/utils';

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
  overflow: hidden;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: calc(46px + 8px);
  width: 100%;
  border-radius: $secondaryBorderRadius;
  background-color: $white;
  box-shadow: 0 1px 10px 0 rgba(34, 34, 34, 0.3);
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;
  @include defaultTransition(background-color);
}

.react-autosuggest__suggestion--highlighted {
  background-color: $blue_1;
}
