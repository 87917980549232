.root {
  display: flex;
  align-items: center;
  margin: -12px;
}
.root_inline {
  display: block;
  margin: -4px;
}

.item {
  padding: 12px;
}

.root_inline .item {
  padding: 4px;
}