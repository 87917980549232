.input {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
}
.input:disabled {
  opacity: 0.6;
}

.container {
  position: relative;
}

.input {
  width: 144px;
  padding: 12px;
  border: 1px solid #d6dbe6;
  border-radius: 2px;
  transition: border 0.2s ease-in-out;
}
.input:focus {
  border: 1px solid #afb5c4;
}

.label {
  position: absolute;
  left: 16px;
  top: -7px;
  display: inline-block;
  padding: 2px 4px;
  background-color: #fff;
  font-size: 12px;
  color: #afb5c4;
  pointer-events: none;
}