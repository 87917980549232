.comment {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.date {
  position: relative;
  margin-right: 7px;
}
.date::after {
  content: "";
  position: absolute;
  right: -4px;
  top: 2px;
  bottom: 2px;
  border-right: 1px solid currentColor;
}