.root {
  display: flex;
  margin-right: 18px;
  overflow: hidden;
}
.root:last-child {
  margin-right: 0;
}
.root_isVisibleWhenRowTranslucent {
  position: relative;
  z-index: 1;
}
.root_horizontalAlignment__center {
  justify-content: center;
}

.content {
  overflow: hidden;
}