.element {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
}
.element:disabled {
  opacity: 0.6;
}

.root {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  padding: 0 24px;
  padding-right: 8px;
  padding-left: 16px;
  border-radius: 24px;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
}
.root_grey {
  background-color: #f5f6f9;
}
.root_highlighted {
  background-color: rgba(62, 126, 255, 0.2);
}

.element {
  flex: 1 1 auto;
  padding: 0 8px;
}
.element::placeholder {
  color: #afb5c4;
}

.prepended {
  flex: 0 0 auto;
  max-height: 100%;
}

.appended {
  flex: 0 0 auto;
  max-height: 100%;
}