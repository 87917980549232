@import '../../styles/utils.scss';

.Select {
  min-width: 140px;
}

.Select__control {
  @extend %normalize-input;
  height: 46px;
  padding-right: 4px;
  border: none !important;
  border-radius: $primaryBorderRadius !important;
  background-color: $grey_1 !important;
  cursor: pointer !important;
  @include defaultTransition(background-color);

  &--is-focused {
    box-shadow: none !important;
  }

  &--menu-is-open {
    position: relative;
    z-index: 2;
    background-color: $white !important;
  }

  &--is-disabled {
    opacity: 0.6;
  }
}

.Select__theme_default {
}

.Select__theme_blue .Select__control {
  background-color: rgba($primaryBlue, 0.2) !important;
}

.Select__theme_white .Select__control {
  background-color: $white !important;
}

.Select__value-container {
  padding-left: 20px !important;
}

.Select__dropdown-indicator {
  color: $black !important;
}

.Select__indicator-separator {
  display: none;
}

.Select__single-value {
  font-weight: 500;
}

.Select__menu {
  margin-top: -46px !important;
  padding-top: 46px;
  border: none !important;
  border-radius: $primaryBorderRadius !important;
  box-shadow: 0 1px 10px 0 rgba(34, 34, 34, 0.3) !important;
  overflow: hidden;
}

.Select__menu-list {
  padding: 0 !important;
}

.Select__option {
  cursor: pointer !important;
  padding: 12px 20px !important;
  @include defaultTransition(background-color);

  &:hover,
  &:focus,
  &--is-focused {
    background-color: $grey_1 !important;
  }

  &--is-selected {
    color: inherit !important;
    background-color: $grey_1 !important;
  }
}
