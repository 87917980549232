.commentButton {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.commentButton:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.commentButton {
  color: #3e7eff;
  transition: color 0.2s ease-in-out;
}
.commentButton:hover {
  color: #0054fd;
}
.commentButton:active {
  color: #3e7eff;
}