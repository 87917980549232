.label {
  display: inline-flex;
  align-items: center;
}

.labelIcon {
  position: relative;
  display: inline-block;
  margin-left: 12px;
  color: #afb5c4;
}

.labelCount {
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3.5px;
  bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}