.warningIcon {
  color: #f7ca45;
}

.count {
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #3e7eff;
  color: #fff;
  font-size: 10px;
}