@import '../../styles/utils';

.circular-progress {
  display: inline-block;
  color: $primaryBlue;
  animation: circular-rotate 1.4s linear infinite; // indeterminate

  svg {
    display: block;
  }

  circle {
    stroke: currentColor;
    animation: circular-dash 1.4s ease-in-out infinite;
    stroke-dasharray: 80px 200px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    fill: none;
  }
}

@keyframes circular-rotate {
  0% {
    transform-origin: 50% 50%; // Fix IE 11 wobbly
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circular-dash {
  0% {
    stroke-dasharray: 1px 200px;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 100px 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px 200px;
    stroke-dashoffset: -125px;
  }
}

/* Styles applied to the `circle` svg path if `disableShrink=true`. */
.circular-progress__circle--no-shrink {
  animation: none;
}
