.button {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  display: flex;
  align-items: center;
}

.counter {
  margin-right: 16px;
  font-weight: 700;
}

.buttonGroup {
  display: flex;
}

.buttonItem {
  margin-right: 8px;
}
.buttonItem:last-child {
  margin-right: 0;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  box-sizing: border-box;
  border-radius: 18px;
  color: #000;
  border: 1px solid currentColor;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}
.button:hover {
  color: #3e7eff;
}
.button:disabled {
  pointer-events: none;
  opacity: 0.4;
}