.root {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  max-width: 540px;
  text-align: center;
}

.title {
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 700;
}

.description {
  font-size: 18px;
  font-weight: 700;
}

.image {
  margin-top: 56px;
  font-size: 280px;
}