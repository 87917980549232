@import '../../../../../node_modules/flatpickr/dist/flatpickr.css';
@import '../../../../styles/utils';

.flatpickr- {
  &calendar,
  &innerContainer,
  &rContainer {
    width: auto !important;
  }

  &rContainer {
    flex: 1 1 auto;
  }

  &input {
    display: none !important;
  }

  &calendar {
    box-shadow: none !important;
  }

  &months {
    margin-bottom: 8px !important;
    padding: 4px 0 !important;
    border-top: 1px solid $grey_2 !important;
  }

  &prev-month {
    top: 6px !important;
  }

  &next-month {
    top: 6px !important;
  }

  &weekdays {
    box-sizing: border-box;
    padding: 0 16px !important;
    padding-bottom: 4px !important;
    border-bottom: 1px solid $grey_2 !important;
  }

  &weekday {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: $black !important;
  }

  &days {
    margin: 0 auto;
    padding-top: 8px !important;
    font-size: 14px !important;
  }

  &day {
    user-select: none;

    &.today {
      border: 1px solid transparent;
      border-radius: $secondaryBorderRadius;
    }

    &:hover {
      border-radius: $secondaryBorderRadius !important;
      background-color: $blue_1 !important;
      border: 1px solid $blue_1 !important;
      color: $black !important;
    }

    &.inRange {
      border-radius: 0 !important;
      background-color: $blue_1 !important;
      border: 1px solid $blue_1 !important;
      box-shadow: -5px 0 0 $blue_1, 5px 0 0 $blue_1 !important;
      color: $black !important;
    }

    &.inRange:hover {
      border-radius: 0 !important;
    }

    &.startRange {
      border-radius: $secondaryBorderRadius 0 0 $secondaryBorderRadius !important;
      border-color: $primaryBlue !important;
      background-color: $primaryBlue !important;
      color: $white !important;
    }

    &.endRange {
      border-radius: 0 $secondaryBorderRadius $secondaryBorderRadius 0 !important;
      border-color: $primaryBlue !important;
      background-color: $primaryBlue !important;
      color: $white !important;
    }

    &.endRange:hover {
      border-radius: 0 $secondaryBorderRadius $secondaryBorderRadius 0 !important;
    }

    &.startRange.endRange {
      border-radius: $secondaryBorderRadius !important;
    }

    &.startRange + &.endRange {
      box-shadow: none !important;
    }

    &.prevMonthDay {
      &:hover {
        color: rgba(57, 57, 57, 0.1) !important;
      }

      &.startRange,
      &.endRange {
        color: $white !important;
      }
    }

    &.nextMonthDay {
      &:hover {
        color: rgba(57, 57, 57, 0.1) !important;
      }

      &.startRange,
      &.endRange {
        color: $white !important;
      }
    }
  }

  &disabled {
    &:hover {
      background-color: transparent !important;
      border: 1px solid transparent !important;
      color: rgba(57, 57, 57, 0.1) !important;
    }
  }
}
