.root {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 100;
  right: 0;
  top: calc(100% + 8px);
  right: -4px;
  min-width: 264px;
  border: 1px solid #f5f6f9;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(34, 34, 34, 0.3);
}
.dropdown__horizontalAlignment_right {
  left: 4px;
  right: auto;
}
.dropdown__verticalPosition_top {
  top: auto;
  bottom: calc(100% + 8px);
}