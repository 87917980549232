.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  border-radius: 24px;
  background-color: #f2f2f7;
  text-decoration: none;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.root:hover, .root:focus {
  background-color: #3e7eff;
}
.root:active {
  background-color: #f2f2f7;
}

.icon {
  margin-right: 6px;
  color: #3e7eff;
  transition: color 0.2s ease-in-out;
}

.root:hover .icon,
.root:focus .icon {
  color: #fff;
}

.root:active .icon {
  color: #3e7eff;
}

.title {
  color: #000;
  font-size: 12px;
  line-height: 1;
  transition: color 0.2s ease-in-out;
}

.root:hover .title,
.root:focus .title {
  color: #fff;
}

.root:active .title {
  color: #000;
}