.root {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  box-sizing: border-box;
  padding: 24px 16px;
  overflow: auto;
}

.window {
  position: relative;
  width: 832px;
  box-sizing: border-box;
  padding: 24px 32px;
  border-radius: 24px;
  box-shadow: 0 50px 150px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #d6dbe6;
}

.title {
  flex: 0 0 auto;
  margin: 0;
  line-height: 1.2;
  font-size: 26px;
}

.titleAppended {
  flex: 1 1 auto;
  margin-left: 16px;
}

.actionGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid #d6dbe6;
}

.actionItem {
  margin-right: 8px;
}
.actionItem:last-child {
  margin-right: 0;
}