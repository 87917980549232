.element {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
}
.element:disabled {
  opacity: 0.6;
}

.root {
  display: flex;
  padding: 16px 24px;
  border-radius: 24px;
  background-color: #f5f6f9;
  overflow: hidden;
}

.element {
  flex: 1 1 auto;
  resize: none;
}
.element::placeholder {
  color: #afb5c4;
}

.appended {
  flex: 0 0 auto;
  margin-right: -8px;
}