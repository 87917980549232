@import '../../styles/utils';

.Toastify__toast-container {
  width: 400px;
}

.Toastify__toast {
  padding: 16px 24px;
  background-color: $textDark;
  font-size: 16px;
  color: $white;
  box-shadow: 0 1px 10px 0 rgba(34, 34, 34, 0.47);
}

.Toastify__toast--error {
  color: $errorRed;
}
