.option {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.option {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
}
.option:hover, .option:focus, .option--is-focused {
  background-color: #f5f6f9;
}