.root {
  margin-bottom: 32px;
  padding: 24px 32px;
  border-radius: 24px;
  background-color: #fff;
}
.root:last-child {
  margin-bottom: 0;
}
.root_grey {
  background-color: #f5f6f9;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  margin: 0;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 700;
}

.titleAppended {
  flex: 1 1 auto;
  margin-left: 16px;
}