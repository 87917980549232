.wrapper {
  position: relative;
  padding: 2px;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  vertical-align: top;
}
.wrapper_disabled {
  cursor: default;
  opacity: 0.6;
}

.element {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mark {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #222;
  border-radius: 2px;
  box-sizing: border-box;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}
.mark::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  display: inline-block;
  width: 4px;
  height: 10px;
  border-radius: 1px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.element:checked + .mark {
  border: 2px solid #3e7eff;
  background-color: #3e7eff;
}
.element:checked + .mark::before {
  opacity: 1;
}

.label {
  margin-left: 8px;
  line-height: 20px;
  font-size: 14px;
}