.headerButton {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headerButton:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.header {
  display: flex;
  margin-bottom: 32px;
}

.headerButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 56px;
  font-weight: 700;
}
.headerButton::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #d6dbe6;
}
.headerButton::after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 4px solid transparent;
  transition: border-top 0.2s ease-in-out;
}
.headerButton:hover::after {
  border-top: 4px solid #0054fd;
}
.headerButton:active::after {
  border-top: 4px solid #3e7eff;
}
.headerButton_active {
  pointer-events: none;
}
.headerButton_active::after {
  border-top: 4px solid #3e7eff;
}

.headerButtonTitle {
  font-size: 20px;
}

.headerButtonTrailingElement {
  margin-left: 8px;
}