@import './utils';

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f7;
  line-height: 1.3;
  font-family: 'Roboto', 'Arial', 'Helvetica', sans-serif;
  font-size: 14px;
  color: $textDark;
}

#root {
  min-width: 1440px;
}

button {
  margin: 0;
}
