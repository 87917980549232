.element {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.element:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.element {
  transition: color 0.2s ease-in-out;
  position: relative;
  padding: 4px;
}
.element:hover {
  color: #3e7eff;
}
.element:disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.counter {
  position: absolute;
  left: 18px;
  top: 18px;
  font-size: 11px;
}