.root {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.root:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  text-decoration: underline;
  color: #3e7eff;
  word-break: break-word;
  text-align: left;
  transition: color 0.2s ease-in-out;
}
.root:hover {
  color: #0054fd;
}
.root_expanded {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}