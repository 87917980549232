.root {
  position: relative;
}

.labelRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.label {
  flex: 0 0 auto;
  margin-right: 16px;
  color: #afb5c4;
}

.content_withDefaultHeight {
  display: flex;
  align-items: center;
  min-height: 46px;
}

.hint {
  position: absolute;
  left: 16px;
  bottom: -2px;
  transform: translateY(100%);
  line-height: 0.9;
  font-size: 10px;
  color: #afb5c4;
}
.hint_failed {
  color: #f5525c;
}

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  flex: 0 0 auto;
  margin-bottom: 8px;
  color: #afb5c4;
}

.content {
  flex: 1 1 auto;
}