// Variables
$white: #fff;
$grey_1: #f5f6f9;
$grey_2: #d6dbe6;
$grey_3: #afb5c4;
$textDarkGrey: #575757;
$textDark: #222;
$dark: #222;
$black: #000;
$blue_1: #ecf3ff;
$primaryBlue: #3e7eff;
$secondaryBlue: #0054fd;
$errorRed: #f5525c;
$red: #d45143;
$green: #3dc746;

$primaryBorderRadius: 24px;
$secondaryBorderRadius: 8px;

// Functions
@function joinTransitions($properties...) {
  $transition: '';

  @each $property in $properties {
    $transition: #{$property} 0.2s ease-in-out, #{$transition};
  }

  @return $transition;
}

// Mixins
@mixin overflowEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin defaultTransition($properties...) {
  transition: joinTransitions($properties...);
}

// Extensible rules
%normalize-button {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  @include overflowEllipsis;

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

%normalize-input {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;

  &:disabled {
    opacity: 0.6;
  }
}
