.root {
  padding: 8px;
  padding-right: 12px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
}

.metaRow {
  display: flex;
  margin-bottom: 8px;
}

.subjectRow {
  flex: 1 1 auto;
  margin-right: 8px;
  display: flex;
  overflow: hidden;
}

.userMedia {
  position: relative;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 32px;
  overflow: hidden;
}

.actionHolder {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.action {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
}

.commentIcon {
  display: inline-block;
  margin-right: 4px;
  color: #3e7eff;
}

.dateRow {
  padding-top: 4px;
  flex: 0 0 auto;
  color: #afb5c4;
  font-size: 12px;
  letter-spacing: -0.3px;
}

.date {
  position: relative;
  margin-right: 7px;
}
.date::after {
  content: "";
  position: absolute;
  right: -4px;
  top: 2px;
  bottom: 2px;
  border-right: 1px solid currentColor;
}

.text {
  word-break: break-word;
  padding-left: 40px;
}