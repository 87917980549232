.root {
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -6px;
}

.item {
  padding: 6px;
}

.label {
  cursor: pointer;
}

.element {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mark {
  display: inline-block;
  padding: 12px 16px;
  border: 1px solid var(--color, #fff);
  border-radius: 24px;
  color: var(--color, #fff);
  font-size: 16px;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.mark:hover {
  opacity: 0.7;
}
.mark:active {
  opacity: 0.9;
}

.element:checked + .mark {
  background-color: var(--color, #fff);
  color: #fff;
}
.element:checked + .mark:hover {
  opacity: 0.8;
}