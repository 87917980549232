.logo {
  max-width: 168px;
  box-sizing: border-box;
  padding: 29px 0;
  padding-left: 24px;
  margin: -4px;
  margin-bottom: 16px;
}

.logoLink {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.routeList {
  padding-right: 24px;
}