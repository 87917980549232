.root {
  display: inline-block;
  height: 24px;
  min-width: 24px;
  vertical-align: top;
}
.root_size--inline {
  height: 1em;
  min-width: 1em;
  line-height: 1;
}
.root_size--large {
  height: 32px;
  min-width: 32px;
}
.root_size--small {
  height: 18px;
  min-width: 18px;
}
.root_size--xsmall {
  height: 16px;
  min-width: 16px;
}
.root_size--xxsmall {
  height: 12px;
  min-width: 12px;
}