.button {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  position: relative;
  border-bottom: solid 1px #d6dbe6;
}
.root:first-child {
  border-top: solid 1px #d6dbe6;
}

.button {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: left;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.buttonLabel {
  flex: 1 1 auto;
  margin-right: 24px;
  line-height: 1.3;
}

.hoverSpreader::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.content {
  padding-bottom: 24px;
}