.row {
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid #d6dbe6;
}
.row:first-child {
  border-top: 1px solid #d6dbe6;
}
.row:last-child {
  border-bottom: none;
}
.row_verticallyCenterAligned {
  align-items: center;
}
.row_highlighted {
  background-color: #ebf2ff;
}

.row_translucent {
  position: relative;
}
.row_translucent::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
}