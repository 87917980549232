.row {
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #d6dbe6;
}
.row:first-child {
  border-top: 1px solid #d6dbe6;
}
.row:last-child {
  border-bottom: none;
}