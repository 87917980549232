.root {
  margin-bottom: 32px;
}
.root:last-child {
  margin-bottom: 0;
}
.root_isVerticallyGrowing {
  flex: 1 1 auto;
}

.title {
  margin: 0;
  margin-bottom: 16px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 700;
}