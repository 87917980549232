.wrapper {
  position: relative;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.wrapper_disabled {
  cursor: default;
  opacity: 0.6;
}

.element {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.mark {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: 40px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #3e7eff;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;
}
.mark::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #3e7eff;
  transition: left 0.3s ease-in-out, background-color 0.2s ease-in-out;
}

.element:checked + .mark {
  background-color: #3e7eff;
}
.element:checked + .mark::before {
  left: 21px;
  background-color: #fff;
}

.label {
  margin-left: 8px;
  line-height: 20px;
  font-size: 14px;
}