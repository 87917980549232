.root {
  position: relative;
  height: 100%;
}

.list {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 24px;
  background-color: #f5f6f9;
  color: #afb5c4;
  font-size: 16px;
  overflow: auto;
  scroll-behavior: smooth;
}
.list_centerAligned {
  align-items: center;
  justify-content: center;
}

.message {
  margin-bottom: 8px;
}
.message:last-child {
  margin-bottom: 0;
}