.root {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 0 24px 24px 0;
  padding-left: 16px;
  color: #575757;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.root:hover {
  color: #3e7eff;
}
.root_active {
  pointer-events: none;
  background-color: #3e7eff;
  color: #fff;
}

.icon {
  display: inline-block;
  margin-right: 24px;
}

.title {
  font-size: 16px;
}