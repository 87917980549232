.root {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #222;
  border-radius: 2px;
  box-sizing: border-box;
  vertical-align: top;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}
.root::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  display: inline-block;
  width: 4px;
  height: 10px;
  border-radius: 1px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.root_active {
  border: 2px solid #222;
  background-color: #222;
}
.root_active::before {
  opacity: 1;
}