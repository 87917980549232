.root {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  outline: none;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.root:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background-color: #3e7eff;
  color: #fff;
  transition: background-color 0.2s ease-in-out;
}
.root:hover, .root:focus {
  background-color: #0054fd;
}
.root_small {
  height: 36px;
  width: 36px;
}

.root_variant--flat {
  background-color: transparent;
  border: 1px solid transparent;
  color: #000;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
}
.root_variant--flat:focus {
  border: 1px solid #afb5c4;
  background-color: transparent;
  color: #000;
}
.root_variant--flat:hover {
  border: 1px solid #afb5c4;
  background-color: transparent;
  color: #000;
}
.root_variant--flat:focus {
  color: #fff;
}
.root_variant--flat:active {
  border: 1px solid #0054fd;
  background-color: #0054fd;
  color: #fff;
}