.pieChartGrid {
  display: flex;
  margin: 0 -12px;
}

.pieChartItem {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 0 12px;
}

.timelineLoader {
  padding: 40px 0;
}

.timeline {
  margin-top: 56px;
}

.timelineItem:not(:last-child) {
  margin-bottom: 16px;
}