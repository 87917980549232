.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  box-sizing: border-box;
  border: 2px solid #d6dbe6;
  border-radius: 24px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
}