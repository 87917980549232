@import '../../../styles/utils.scss';

.FilterSelect {
  min-width: auto;
}

.FilterSelect__control {
  @extend %normalize-input;
  height: 24px;
  padding-right: 4px;
  border: none !important;
  border-radius: $primaryBorderRadius !important;
  background-color: $white !important;
  font-weight: 500;
  cursor: pointer !important;
  @include defaultTransition(background-color);

  &--is-focused {
    box-shadow: none !important;
  }
}

.FilterSelect__placeholder {
  font-weight: 500;
  @include overflowEllipsis;
}

.FilterSelect__indicator {
  padding: 4px !important;
  color: $black !important;
}

.FilterSelect__indicator-separator {
  display: none;
}

.FilterSelect__highlighted .FilterSelect__control {
  background-color: rgba($primaryBlue, 0.2) !important;
}

.FilterSelect__menu {
  z-index: 100 !important;
  min-width: 224px;
  border: none !important;
  border-radius: $secondaryBorderRadius !important;
  box-shadow: 0 1px 10px 0 rgba(34, 34, 34, 0.3) !important;
  overflow: hidden;
}

.FilterSelect__option {
  cursor: pointer !important;
  font-weight: 500;
  @include defaultTransition(background-color);

  &:hover,
  &:focus,
  &--is-focused {
    background-color: $grey_1 !important;
  }

  &--is-selected {
    color: inherit !important;
    background-color: $grey_1 !important;
  }
}
