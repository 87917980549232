.root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px 32px;
  border-radius: 24px;
  background-color: #fff;
}
.root_stretched {
  height: 100%;
}

.header {
  flex: 0 0 auto;
  margin-bottom: 24px;
}

.titleRow {
  display: flex;
  align-items: center;
}

.title {
  flex: 0 0 auto;
  margin: 0;
  margin-right: 24px;
  line-height: 1.2;
  font-size: 26px;
}

.titleAppended {
  flex: 1 1 auto;
}

.subtitleElement {
  margin-top: 16px;
}

.content {
  flex: 1 1 auto;
}