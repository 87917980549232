.root {
  display: inline-flex;
  flex-direction: column;
  padding: 24px 0;
}

.inputGroup {
  display: flex;
  justify-content: space-between;
  margin: -8px;
  padding: 0 16px;
}

.inputItem {
  padding: 8px;
}

.datePicker {
  margin: 12px 0 24px;
}

.applyButton {
  padding: 0 16px;
}