.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.labelItem {
  margin-right: 8px;
}
.labelItem:last-child {
  margin-right: 0;
}