.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 104px;
  height: 104px;
  box-sizing: border-box;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 24px;
}

.number {
  margin-bottom: 4px;
  font-size: 30px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.number_minimized {
  margin-bottom: 2px;
  font-size: 25px;
}

.line {
  height: 2px;
  margin-bottom: 3px;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.1;
  font-size: 10px;
}